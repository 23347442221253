const factory = {
  euroCentsToEurosWithoutCents: (value) =>
    (value !== null && Math.round(value / 100).toLocaleString()) || '',
  euroCentsToEurosWithoutCentsWithoutLocal: (value) =>
    (value !== null && Math.round(value / 100)) || '',
  number: (value) =>
    (value !== null && value !== undefined && value.toLocaleString()) || '',
  round: (value) => Math.round(value),
  round2: (value) => Math.round(value * 100) / 100,
  percent: (value, locale) => {
    if (value === null) {
      return null;
    } else {
      return (
        Intl.NumberFormat(locale, {
          maximumFractionDigits: 2,
        }).format(value * 100) + '%'
      );
    }
  },
  noFormat: (value) => value,
  secondsToDays: (value) => Math.round(value / 864) / 100,
};

export default factory;
