import React, { useCallback, useEffect, useState } from 'react';

import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

import chartColors from './chartColors.js';

import fr from 'apexcharts/dist/locales/fr.json';
import en from 'apexcharts/dist/locales/en.json';
import es from 'apexcharts/dist/locales/es.json';

import Helpers from './SBIChartHelpers';

export default function MultiDateTimeChart(props) {
  const { t, i18n } = useTranslation('translation');

  const getColors = useCallback(() => {
    const result = props.series.map(function (seriesName, index) {
      if (props.custom_colors) {
        return props.custom_colors[seriesName] || chartColors[index];
      }
      return chartColors[index];
    });
    return result.filter((elem) => !!elem);
  }, [props.custom_colors, props.data]);

  const [state, setState] = useState({
    options: {
      chart: {
        stacked: props.stacked || false,
        zoom: {
          enabled: false,
        },
        locales: [fr, en, es],
        defaultLocale: i18n.language.substring(0, 2),
        toolbar: {
          tools: {
            download: "<img src='/assets/icons/download.svg' width='16px' />",
          },
          export: {
            csv: {
              columnDelimiter: Helpers.csvSeparator(i18n.language),
            },
          },
        },
      },
      stroke: {
        width: 4,
      },
      xaxis: {
        type: 'datetime',
      },
      yaxis: {
        labels: {
          /*istanbul ignore next*/
          formatter(value) {
            return value.toLocaleString();
          },
        },
      },
      legend: {
        position: 'top',
      },
      fill: {
        opacity: 1,
      },
      colors: getColors(),
      tooltip: {
        x: {
          format: 'dddd dd MMMM',
        },
      },
    },
    series: [],
  });

  // Refresh when props are changing (i.e change in filters)
  useEffect(() => {
    setState({
      options: {
        chart: {
          stacked: props.stacked || false,
          zoom: {
            enabled: false,
          },
          locales: [fr, en],
          defaultLocale: i18n.language.substring(0, 2),
          toolbar: {
            tools: {
              download: "<img src='/assets/icons/download.svg' width='16px' />",
            },
          },
        },
        stroke: {
          width: 4,
        },
        xaxis: {
          type: 'datetime',
        },
        legend: {
          position: 'top',
        },
        fill: {
          opacity: 1,
        },
        colors: getColors(),
        tooltip: {
          x: {
            format: 'dddd dd MMMM',
          },
        },
      },
      series: props.series.map((dataseries) => {
        return {
          name: t(`views.orders.${dataseries}`),
          type: props.chart_types[dataseries],
          data: props.data[dataseries],
        };
      }),
    });
  }, [props, i18n.language, getColors]);

  return (
    <Chart
      options={state.options}
      series={state.series}
      type="line"
      width="100%"
      height={props.height}
    />
  );
}
