import React from 'react';
import ChartCard from '../../ContentCard/ChartCard';
import StatsCard from '../../ContentCard/StatsCard';
import ContentCard from '../../ContentCard/ContentCard';
import { useTranslation } from 'react-i18next';
import {
  categoryWithLabelsWrapper,
  categoryWrapper,
  datetimeWrapper,
  multiDateTimeWrapper,
  simpleArrayWrapper,
} from '../../../DataTransformFactory';
import Formatter from '../../../FormatFactory';
import Reducers from '../../../ReducerFactory';
import { useFilter } from '../../../providers';

export default function Revenue(props) {
  const { t } = useTranslation('translation');
  const { filter } = useFilter();

  const revenue_stats_items = [
    {
      title: 'views.revenue.total',
      reducer: Reducers.total,
      formatter: Formatter.euroCentsToEurosWithoutCents,
    },
  ];

  if (!filter.singleDay) {
    revenue_stats_items.push({
      title: 'views.revenue.average',
      reducer: Reducers.avg,
      formatter: Formatter.euroCentsToEurosWithoutCents,
    });
  }

  const revenue_stats = {
    title: t('views.revenue.totals'),
    items: revenue_stats_items,
    api: {
      module: 'analytics',
      path: 'orders_revenue/daily/v2',
      data_wrapper_opts: { key: 'revenue_in_euro_cents', data_key: 'data' },
      data_wrapper: simpleArrayWrapper,
    },
    height: 120,
    component: StatsCard,
  };

  const daily_revenue = {
    xtype: 'datetime',
    title: t('views.revenue.command_revenue_figures'),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.CA'),
    },
    formatter: Formatter.euroCentsToEurosWithoutCentsWithoutLocal,
    api: {
      module: 'analytics',
      path: 'orders_revenue/daily/v2',
      data_wrapper_opts: {
        x: 'date',
        y: 'revenue_in_euro_cents',
        data_key: 'data',
      },
      data_wrapper: datetimeWrapper,
    },
    type: 'line',
    orientation: 'vertical',
    height: 400,
    component: ChartCard,
  };

  const daily_revenue_by_source = {
    xtype: 'multiseriesdatetime',
    title: t('views.revenue.daily_revenue_by_source'),
    labels: {
      index: t('charts.units.date'),
      value: t('charts.units.CA'),
    },
    formatter: Formatter.euroCentsToEurosWithoutCentsWithoutLocal,
    api: {
      module: 'analytics',
      path: 'daily_orders_revenue/by_source/v2',
      data_wrapper_opts: {
        x: 'date',
        data_key: ['daily_values', 'data'],
        excluded_cols: ['index', 'revenue_in_euro_cents'],
      },
      data_wrapper: multiDateTimeWrapper,
    },
    stacked: false,
    type: 'line',
    orientation: 'vertical',
    height: 400,
    component: ChartCard,
  };

  const revenue_per_origin = {
    xtype: 'category',
    title: t('views.revenue.origin_revenue_figures'),
    labels: {
      index: t('charts.units.origin'),
      value: t('charts.units.CA'),
    },
    formatter: Formatter.euroCentsToEurosWithoutCents,
    disableLabelFormatter: true,
    api: {
      module: 'analytics',
      path: 'orders_revenue/by_origin/v2',
      data_wrapper_opts: {
        x: 'origin',
        y: 'revenue_in_euro_cents',
        data_key: 'data',
      },
      data_wrapper: categoryWrapper,
    },
    type: 'bar',
    orientation: 'horizontal',
    height: 400,
    component: ChartCard,
  };

  const revenue_per_origin_percent = {
    xtype: 'percent',
    title: t('views.revenue.origin_revenue_figures'),
    labels: {
      index: t('charts.units.origin'),
      value: t('charts.units.CA'),
    },
    formatter: Formatter.euroCentsToEurosWithoutCents,
    disableLabelFormatter: true,
    api: {
      module: 'analytics',
      path: 'orders_revenue/by_origin/v2',
      data_wrapper_opts: {
        label: 'origin',
        value: 'revenue_in_euro_cents',
        data_key: 'data',
      },
      data_wrapper: categoryWithLabelsWrapper,
    },
    height: 400,
    component: ChartCard,
  };

  const revenue_per_source = {
    xtype: 'category',
    title: t('views.revenue.source_revenue_figures'),
    labels: {
      index: t('charts.units.source'),
      value: t('charts.units.CA'),
    },
    formatter: Formatter.euroCentsToEurosWithoutCents,
    api: {
      module: 'analytics',
      path: 'orders_revenue/by_source/v2',
      data_wrapper_opts: {
        x: 'source',
        y: 'revenue_in_euro_cents',
        data_key: 'data',
      },
      data_wrapper: categoryWrapper,
    },
    type: 'bar',
    orientation: 'horizontal',
    height: 400,
    component: ChartCard,
  };

  const revenue_per_source_percent = {
    xtype: 'percent',
    title: t('views.revenue.source_revenue_figures'),
    labels: {
      index: t('charts.units.source'),
      value: t('charts.units.CA'),
    },
    formatter: Formatter.euroCentsToEurosWithoutCents,
    api: {
      module: 'analytics',
      path: 'orders_revenue/by_source/v2',
      data_wrapper_opts: {
        label: 'source',
        value: 'revenue_in_euro_cents',
        data_key: 'data',
      },
      data_wrapper: categoryWithLabelsWrapper,
    },
    height: 400,
    component: ChartCard,
  };

  return [
    <div
      data-testid="revenue-pane"
      className="column is-full"
      key="revenue_stats"
    >
      <ContentCard {...props} {...revenue_stats} />
    </div>,
    <>
      {filter.singleDay
        ? null
        : [
            <div className="column is-full" key="daily_revenue">
              <ContentCard {...props} {...daily_revenue} />
            </div>,
            <div className="column is-full" key="daily_revenue_by_source">
              <ContentCard {...props} {...daily_revenue_by_source} />
            </div>,
          ]}
    </>,
    /*<div className="column is-full is-half-widescreen" key="revenue_per_origin">
      <ContentCard {...props} {...revenue_per_origin} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="revenue_per_origin_percent"
    >
      <ContentCard {...props} {...revenue_per_origin_percent} />
    </div>,*/
    <div className="column is-full is-half-widescreen" key="revenue_per_source">
      <ContentCard {...props} {...revenue_per_source} />
    </div>,
    <div
      className="column is-full is-half-widescreen"
      key="revenue_per_source_percent"
    >
      <ContentCard {...props} {...revenue_per_source_percent} />
    </div>,
  ];
}
